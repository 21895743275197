import React, { PureComponent } from "react";
import { notification } from "antd";
import gstore from "../../stores/gstore";
import SingleEntityInput, { ISingleEntityInputProps } from "../SingleEntityInput";

class UserInput extends PureComponent<Omit<ISingleEntityInputProps, 'action' | 'renderItem' | 'renderSelectedItem'> & { role?: 'user' | 'executor' }> {
	render() {
		return (
			<SingleEntityInput
				extras={this.props.role ? { role: this.props.role } : void 0}
				action={async (id, query, extras) => {
					const res = await gstore.api.search('user', id, query, extras);
					if (res.result) {
						console.log('res.data: ', res.data);
						return res.data;
					} else {
						notification.error({
							message: 'Произошла ошибка при загрузке записей'
						});
						return [];
					}
				}}
				renderItem={item => (
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
						<img src={gstore.api.fileLink(item.payload.photoId) || 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'} style={{ width: 20, height: 20, borderRadius: 10, marginRight: 10 }} />
						{item.text} ({item.payload.email})
					</div>
				)}
				{...this.props}
			/>
		);
	}
}

export default UserInput;