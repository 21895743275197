import React, { PureComponent } from "react";

import { observer } from "mobx-react";

import Ava from "../../controls/Ava";

import gstore from "../../stores/gstore";
import EntityPage from "../../controls/EntityPage";

@observer
class KVSPage extends PureComponent<{}> {
	render() {
		return (
			<EntityPage
				listEndpoint={`/kvs/admin/`}
				editEndpoint={gstore.api.editKV}
				editTitle="Редактировать текст"
				search={true}
				form={[
					{
						type: 'richtext',
						field: 'value',
						label: 'Значение',
						rules: [{ required: true, message: 'Пожалуйста, введите значение' }]
					},
				]}
				columns={[
					{
						name: 'Идентификатор',
						field: 'id',
						weight: 1
					},
					{
						name: 'Значение',
						field: 'value',
						weight: 1
					},
				]}
			/>
		);
	}
}

export default KVSPage;