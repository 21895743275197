import { Modal } from "antd";
import { observable, runInAction } from "mobx";
import React from "react";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import API from "../network/api";

class GlobalStore {

	api: API = new API('/api');

	@observable globalError: string = '';
	@observable me: null | { id: string; email: string; role: string } = null;

	@observable tags: { id: string, name: string }[] = [];

	@observable promptVisible: boolean = false;
	@observable promptTitle = '';
	@observable promptText = '';
	
	promptResolve: any = () => {};

	async init() {
		const meResult = await this.api.me();
		if (meResult.result) {
			this.me = meResult.data;
			// this.tags = (await this.api.tags()).data!;
		} else {
			this.globalError = 'Server error';
		}
	}

	parseInlineIds(raw: string): string[] {
		const data = raw.substring(1, raw.length - 1);
		if (!data) {
			return [];
		} else {
			return data.split('|');
		}
	}

	buildInlineIds(ids: string[]): string {
		return '|' + ids.join('|') + '|';
	}

	prompt(title: string, text: string) {
		return new Promise((resolve, reject) => {
			Modal.confirm({
				title: title,
				icon: <ExclamationCircleOutlined />,
				content: text,
				onOk() {
				  resolve(true);
				},
				onCancel() {
				  resolve(false);
				},
			});
		})
	}

}

const gstore = new GlobalStore();

export default gstore;