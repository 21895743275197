import React, { PureComponent } from 'react';

import { Layout, Menu, Breadcrumb, Form, Input, Button, Alert } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import './style.scss';
import Centroid from '../../controls/Centroid';
import { autobind } from 'core-decorators';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import gstore from '../../stores/gstore';

import logo from '../../logo-big.png';
import title from '../../title.png';

const { Header, Content } = Layout;

@observer
class AuthPage extends PureComponent {

	@observable isLoading = false;
	@observable error = '';

	@autobind
	async onFinish({ email, password }: { email: string, password: string }) {
		this.isLoading = true;
		const loginResult = await gstore.api.login(email, password);

		if (loginResult.result) {
			await gstore.init();
		} else {
			if (loginResult.error === 'USER_NOT_FOUND') {
				this.error = 'Такой пользователь не найден. Проверьте правильность данных.';
			} else {
				this.error = 'Ошибка сервера';
			}
		}

		this.isLoading = false;
	};
	
	@autobind
	onFinishFailed(errorInfo: any) {
		console.log('Failed:', errorInfo);
	};

	render() {
		return (
			<Layout>
				<Header className="header">
					<div className="logo">
						<img src={logo} style={{ maxHeight: 40 }} />
						{/* <img src={title} style={{ maxHeight: 40 }} /> */}
					</div>
				</Header>
				<Layout>
					<Layout style={{ padding: '0 24px 24px' }}>
						<Breadcrumb style={{ margin: '16px 0' }}>
							<Breadcrumb.Item>Панель администратора</Breadcrumb.Item>
							<Breadcrumb.Item>Авторизация</Breadcrumb.Item>
						</Breadcrumb>
						<Content
							className="site-layout-background"
							style={{
								padding: 24,
								margin: 0,
								minHeight: 280,
							}}
						>
							<Centroid>
								<Form
									layout="vertical"
									name="basic"
									initialValues={{ remember: true }}
									onFinish={this.onFinish}
									onFinishFailed={this.onFinishFailed}
									style={{
										width: 300
									}}
								>
									{this.error ? (<Alert style={{ marginBottom: 30 }} message={this.error} type="error" />) : null}
									<Form.Item
										label="Email"
										name="email"
										rules={[{ required: true, message: 'Пожалуйста, введите email' }]}
									>
										<Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
									</Form.Item>

									<Form.Item
										label="Пароль"
										name="password"
										rules={[{ required: true, message: 'Пожалуйста, введите пароль' }]}
									>
										<Input.Password placeholder="Пароль" prefix={<LockOutlined className="site-form-item-icon" />} />
									</Form.Item>

									<Form.Item style={{ marginTop: 50 }}>
										<Button loading={this.isLoading} type="primary" htmlType="submit">
											Авторизоваться
										</Button>
									</Form.Item>
								</Form>
							</Centroid>
						</Content>
					</Layout>
				</Layout>
			</Layout>
		);
	}
}

export default AuthPage;