import React, { PureComponent } from "react";

import { Select } from "antd";
import gstore from "../../stores/gstore";

const { Option } = Select;

export interface ITagsInputProps {
	value?: string[];
	onChange?: (value: string[]) => void,
}

class TagsInput extends PureComponent<ITagsInputProps> {

	render() {
		const { value, onChange } = this.props;
		return (
			<Select
				mode="multiple"
				allowClear
				style={{ width: '100%' }}
				placeholder="Выберите квалификации"
				value={value}
				onChange={onChange}
			>
				{gstore.tags.map((tag, idx) => (
					<Option key={tag.id} value={tag.id}>{tag.name}</Option>
				))}
			</Select>
		);
	}
}

export default TagsInput