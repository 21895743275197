import React, { PureComponent } from "react";

import { observer } from "mobx-react";
import { ShoppingCartOutlined, DeleteOutlined } from '@ant-design/icons';

import gstore from "../../stores/gstore";
import EntityPage from "../../controls/EntityPage";
import { IFormRow } from "../../controls/GenericForm";
import Ava from "../../controls/Ava";
import { IFTColumn, IFTRow } from "../../controls/FTable";
import { observable } from "mobx";
import Modal from "antd/lib/modal/Modal";
import { Button, Tooltip } from "antd";

import swal from 'sweetalert';

import moment from "moment";

@observer
class UsersPage extends PureComponent<{ role: 'user' | 'admin' | 'executor' }> {

	@observable userVisible = false;
	@observable selectedUser: any = null;

	renderBoughts() {
		if (!this.selectedUser) {
			return null;
		}

		return (
			<div style={{ paddingTop: 40, display: 'flex', flexDirection: 'column', alignItems: 'stretch', justifyContent: 'flex-start' }}>
				{!this.selectedUser.boughtPlays.length ? (
					<div>Купленные спектакли отсутствуют</div>
				) : null}
				{this.selectedUser.boughtPlays.map((b: any, idx: number) => (
					<div key={b.playId} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', height: 50, borderBottom: '1px solid #e0e0e0', marginBottom: 10, paddingBottom: 10 }}>
						<div style={{ flex: 1 }}>
							Спектакль: <b>{b.play.title}</b><br />
							{(b.type === 'g') ? `Куплен через Google Play за ${parseFloat(b.price)} руб.` : ((b.type === 'a') ? `Куплен через Apple Pay за ${b.price} руб.` : 'Куплен по промокоду')}
						</div>
						<div style={{ flex: 0 }}>
							<Tooltip title="Отменить покупку">
								<Button
									danger={true}
									title="Отменить покупку"
									onClick={async (e) => {
										e.preventDefault();
										this.selectedUser.boughtPlays.splice(idx, 1);
									}}
								>
									<DeleteOutlined />
								</Button>
							</Tooltip>
						</div>
					</div>
				))}
			</div>
		)
	}

	ep = React.createRef<EntityPage<any>>();

	render() {
		let extras: IFTColumn[] = [];

		if (this.props.role === 'executor') {
			extras = [
				{
					name: 'Квалификации',
					field: 'tags',
					weight: 2,
					renderCell: (value: string) => {
						const cont = value.substring(1, value.length - 1);
						const tags = cont ? cont.split('|').map(id => gstore.tags.find(r => r.id === id)!.name) : [];
						if (!tags.length) {
							return <div className="muted">Пусто</div>;
						}
						return (
							<div>{tags.join(', ')}</div>
						)
					}
				},
			]
		}
		
		return (
			<>
				<Modal
					maskClosable={false}
					title="Управление покупками"
					centered
					visible={this.userVisible}
					onOk={async () => {
						await gstore.api.editUser({
							id: this.selectedUser.id,
							boughtPlays: this.selectedUser.boughtPlays
						}).then(_ => {
							this.userVisible = false;
							this.ep.current!.tableRef.load(this.ep.current!.tableRef.props);
						});
					}}
					cancelText="Отмена"
					onCancel={() => this.userVisible = false}
					okText="Сохранить"
					width={600}
				>
					{this.renderBoughts()}
				</Modal>
				<EntityPage
					ref={this.ep}
					listEndpoint={`/user/${this.props.role}/`}
					editEndpoint={this.props.role === 'user' ? void 0 : gstore.api.editUser}
					editTitle="Редактировать администратора"
					search={this.props.role === 'user'}
					formValuesToFields={raw => ({
						...raw,
						tags: gstore.parseInlineIds(raw.tags || '||'),
					})}
					formFieldsToValues={raw => ({
						...raw,
						tags: gstore.buildInlineIds(raw.tags || []),
						role: this.props.role,
					})}
					customRowActions={[
						(id: string, row: IFTRow) => ({
							onClick: (id: string, row: IFTRow) => {
								this.selectedUser = JSON.parse(JSON.stringify(row));
								this.userVisible = true;
							},
							icon: <ShoppingCartOutlined />,
							buttonProps: {
								disabled: row.boughtPlays.length === 0
							},
							tooltip: 'Управление покупками (' + row.boughtPlays.length + ')'
						})
					]}
					form={[
						{
							type: 'string',
							field: 'name',
							label: 'Имя',
							rules: [{ required: true, message: 'Пожалуйста, введите имя' }]
						},
						{
							type: 'string',
							field: 'email',
							label: 'Email',
							rules: [{ required: true, message: 'Пожалуйста, введите email' }]
						},
						{
							type: 'password',
							field: 'password',
							label: 'Пароль',
							rules: [{ required: true, message: 'Пожалуйста, введите пароль' }]
						},
						{
							type: 'image',
							field: 'photoId',
							label: 'Аватар',
							options: {
								entityType: 'user',
							}
						},
						...((this.props.role === 'executor') ? [
							{
								type: 'tags',
								field: 'tags',
								label: 'Квалификации',
								placeholder: 'Квалификации'
							},
						] as IFormRow[] : []),
					]}
					columns={observable(this.props.role === 'user' ? [
						{
							name: 'Имя',
							field: 'name',
							weight: 1
						},
						{
							name: 'Email',
							field: 'email',
							weight: 1
						},
						{
							name: 'Дата принятия соглашения',
							field: 'acceptTimestamp',
							weight: 1,
							renderCell: (value: number | null) => {
								return value ? <span>{moment(value).format('DD.MM.YYYY HH:m')}</span> : <span>Нет</span>;
							}
						},
						{
							name: 'Производитель',
							field: 'manufacturer',
							weight: 1,
						},
						{
							name: 'Бренд',
							field: 'brand',
							weight: 1,
						},
						{
							name: 'ID устройства',
							field: 'deviceId',
							weight: 1,
						},
						{
							name: 'Тип устройства',
							field: 'deviceType',
							weight: 1,
						},
						{
							name: 'Имя устройства',
							field: 'deviceName',
							weight: 1,
						},
						{
							name: 'Модель',
							field: 'model',
							weight: 1,
						},
					] : [
						{
							name: 'Email',
							field: 'email',
							weight: 1
						},
						{
							name: 'Имя',
							field: 'name',
							weight: 1
						},
						...extras,
						{
							name: 'Фото',
							field: 'photoId',
							width: 140,
							renderCell: (value: string | null) => {
								return (
									value ? (
										<Ava src={gstore.api.fileLink(value)} />
									) : (
										<div className="muted">Пусто</div>
									)
								);
							},
						},
					])}
				/>
			</>
		);
	}
}

export default UsersPage;