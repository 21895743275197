import React, { PureComponent, ReactNode } from "react";

import { Button, Form, Select, Spin } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { autobind } from "core-decorators";
import gstore from "../../stores/gstore";
import { observable } from "mobx";
import { observer } from "mobx-react";

const { Option } = Select;

export interface ISimpleQueryActionResult {
	id: string;
	text: string;
	payload?: any;
}

export interface ISingleEntityInputProps {
	value?: string | null;
	extras?: any;
	onChange?: (value: string | null) => void;

	placeholder?: string;
	allowNull?: boolean;
	action: (id: string | null, query: string, extras?: any) => Promise<ISimpleQueryActionResult[]>;
	renderItem?: (item: ISimpleQueryActionResult) => ReactNode;
	renderSelectedItem?: (item: ISimpleQueryActionResult) => ReactNode;
}

@observer
class SingleEntityInput extends PureComponent<ISingleEntityInputProps> {

	@observable loading = false;
	@observable results: ISimpleQueryActionResult[] = [];

	@autobind
	async handleSearch(query: string) {
		this.results = [];

		this.loading = true;

		this.results = await this.props.action(null, query, this.props.extras);

		this.loading = false;
	}

	async componentDidMount() {
		this.loading = true;
		this.results = await this.props.action(this.props.value || null, '', this.props.extras);
		this.loading = false;
	}

	render() {
		const { placeholder, renderItem } = this.props;
		return (
			<Select
				showSearch
				defaultActiveFirstOption={false}
				showArrow={false}
				filterOption={false}
				allowClear
				notFoundContent={this.loading ? <Spin size="small" /> : null}
				onSearch={this.handleSearch}
				style={{ width: '100%' }}
				placeholder={placeholder}
				value={this.props.value || '$$null'}
				onChange={val => {
					if (!this.props.onChange) {
						return;
					}
					if (val === '$$null') {
						this.props.onChange(null);
					} else {
						this.props.onChange(val);
					}
				}}
			>
				<Option key="$$null" value="$$null">Не выбран</Option>
				{this.results.map((item, idx) => (
					<Option key={item.id} value={item.id}>{renderItem ? renderItem(item) : item.text}</Option>
				))}
			</Select>
		);
	}
}

export default SingleEntityInput