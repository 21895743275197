import React, { PureComponent, ReactNode } from "react";

import { Button, Checkbox, Form, Input, Modal, Spin } from "antd";
import { CloseOutlined } from '@ant-design/icons';

import { action, observable, reaction, toJS } from "mobx";
import { observer } from "mobx-react";
import FTable, { IFTColumn } from "../FTable";
import { ISimpleQueryActionResult } from "../SingleEntityInput";

import './style.scss';

export interface IMultipleEntityInputProps {
	changeTitle?: string;
	value?: string[];
	onChange?: (newVal: string[]) => void,
	placeholder?: string;
	action: (ids: string[], query: string) => Promise<ISimpleQueryActionResult[]>;
	
	renderSelectedItem: (item: ISimpleQueryActionResult) => ReactNode;

	modalTitle?: string;
	columns: IFTColumn[];
}

@observer
class MultipleEntityInput extends PureComponent<IMultipleEntityInputProps> {

	@observable loading = false;

	@observable modalVisible = false;
	@observable results: ISimpleQueryActionResult[] = [];

	@observable value = this.props.value || [];
	@observable query = '';

	@observable cache: Record<string, ISimpleQueryActionResult> = {};

	getUncachedIds(ids: string[]): string[] {
		return ids.filter(id => !this.cache[id]);
	}

	dispose: any = null;

	async componentDidMount() {
		const newIds = this.getUncachedIds(this.value);
		(await this.props.action(newIds, '')).map(r => {
			this.cache[r.id] = r;
		});

		this.dispose = reaction(() => this.value.map(v => v), () => {
			this.props.onChange && this.props.onChange(toJS(this.value));
		})
	}

	componentWillUnmount() {
		this.dispose();
	}

	@action
	async load() {
		this.loading = true;

		this.results = await this.props.action([], this.query);

		for (let item of this.results) {
			if (!this.cache[item.id]) {
				this.cache[item.id] = item;
			}
		}

		this.loading = false;
	}

	render() {
		const { columns, placeholder, renderSelectedItem } = this.props;
		return (
			<>
				<div className="mei-block">
					<div className="mei-selected">
						{this.value.map((v, idx) => {
							if (this.cache[v]) {
								return <div className="mei-row" key={v}>
									{renderSelectedItem(this.cache[v])}
									<Button className="mei-row-delete-btn" icon={<CloseOutlined />} type="text" onClick={e => {
										this.value.splice(this.value.indexOf(v), 1);
									}} />
								</div>;
							} else {
								return <div className="mei-row" key={v}><Spin size="small" /></div>;
							}
						})}
					</div>
					<div>
						<Button loading={this.loading} onClick={async (e) => { await this.load(); this.modalVisible = true; }}>{this.props.changeTitle || 'Выбрать'}</Button>
					</div>
				</div>
				{this.modalVisible ? (
					<Modal
						maskClosable={false}
						title={(this.props.modalTitle || `Выберите записи`) + ` - выбрано: ${this.value.length}`}
						centered
						visible={true}
						onOk={() => {
							this.modalVisible = false;
						}}
						cancelText="Отмена"
						onCancel={() => {
							this.modalVisible = false;
						}}
						width={600}
					>
						<div className="mei-filter">
							<Input.Search placeholder="Поиск..." value={this.query} loading={this.loading} onChange={(e) => {
								this.query = e.target.value;
								this.load();
							}} />
						</div>
						<FTable
							columns={[
								{
									name: '',
									field: 'id',
									renderCell: (value, row, rowIndex, col, data) => {
										return (
											<Checkbox
												checked={this.value.includes(value)}
												onChange={(e) => {
													const idx = this.value.indexOf(value);
													if (idx > -1) {
														this.value.splice(idx, 1);
													} else {

														this.value.push(value);
													}
												}}
											/>
										);
									}
								},
								...columns
							]}
							data={this.results.map(item => ({
								id: item.id,
								...item.payload
							}))}
						/>
					</Modal>
				) : null}
			</>
		);
	}
}

export default MultipleEntityInput