import React, { PureComponent, ReactNode } from "react";

import { observable } from "mobx";
import { observer } from "mobx-react";

import { autobind } from "core-decorators";

import { Alert, Form, Input, InputNumber, Modal, Select } from "antd";
import { FormInstance, Rule } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";

import FileInput from "../../controls/FileInput";
import UserInput from "../../controls/UserInput";
import PlayInput from "../PlayInput";
import ServicesEntityInput from "../ServicesEntityInput";
import TagsInput from "../TagsInput";
import CartInput from "../CartInput";
import RichTextInput from "../RichTextInput";
import gstore from "../../stores/gstore";
import moment from "moment";

export interface IFormRow {
	type: 'string' | 'audio' | 'play' | 'password' | 'richtext' | 'text' | 'number' | 'user' | 'services' | 'cart' | 'tags' | 'select' | 'date' | 'image'; // etc
	options?: any;
	field: string;
	label: string;
	placeholder?: string;
	rules?: Rule[];
	hint?: string;

	controlProps?: any;

	rowValueToFieldValue?: (rowValue: any) => any;
	fieldValueToEntityValue?: (fieldValue: any) => any;
}

export type FormSubmitResult = { result: true } | { result: false, error: string };

export interface IGenericFormProps {
	initialValues?: any;
	form: IFormRow[];
	formName?: string;

	fileEntityId?: string;

	valuesToFields?: (rawValues: any) => any;
	fieldsToValues?: (rawValues: any) => any;

	onValidationError?: (errors: any[]) => void,
	onSubmit: (data: any) => Promise<FormSubmitResult>;
}

@observer
class GenericForm extends PureComponent<IGenericFormProps> {

	formRef = React.createRef<FormInstance>();

	@observable error = '';
	@observable loading = false;

	submit() {
		this.formRef.current?.submit();
	}

	@autobind
	async onFinish(data: any) {
		let postData: any = {};
		
		for (let row of this.props.form) {
			let rawValue = data[row.field];
			if (row.type === 'image' || row.type === 'audio') {
				if (rawValue?.response?.result) {
					rawValue = rawValue.response.data.id;
				} else {
					if (typeof rawValue === 'string') {
					} else {
						rawValue = null;
					}
				}
			} else
			if (row.type === 'date' && rawValue) {
				rawValue = (new Date(rawValue)).toUTCString();
			}
			if (row.fieldValueToEntityValue) {
				postData[row.field] = row.fieldValueToEntityValue(rawValue);
			} else {
				postData[row.field] = rawValue;
			}
		}

		if (this.props.fieldsToValues) {
			postData = this.props.fieldsToValues(postData);
		}
		
		this.loading = true;
		try {
			const res = await this.props.onSubmit(postData);
			this.loading = false;
			if (!res.result) {
				this.error = res.error;
			}
		} catch (err) {
			console.log('error: ', err);
			this.loading = false;
			this.error = 'Form error';
			return;
		}
	}

	@autobind
	private renderControl(formRow: IFormRow): Exclude<ReactNode, null | undefined> {
		if (formRow.type === 'string') {
			return <Input placeholder={formRow.placeholder} {...formRow.options} />;
		} else
		if (formRow.type === 'password') {
			return <Input.Password placeholder={formRow.placeholder} {...formRow.options} />;
		} else
		if (formRow.type === 'text') {
			return <TextArea placeholder={formRow.placeholder} {...formRow.options} />;
		} else
		if (formRow.type === 'richtext') {
			return <RichTextInput placeholder={formRow.placeholder} {...formRow.options} />;
		} else
		if (formRow.type === 'date') {
			return <Input type="datetime-local" placeholder={formRow.placeholder} {...formRow.options} />;
		} else
		if (formRow.type === 'image') {
			return <FileInput placeholder={formRow.placeholder} {...formRow.options} entityId={this.props.fileEntityId} />;
		} else
		if (formRow.type === 'audio') {
			return <FileInput placeholder={formRow.placeholder} {...formRow.options} entityId={this.props.fileEntityId} />;
		} else
		if (formRow.type === 'number') {
			return <InputNumber placeholder={formRow.placeholder} {...formRow.options} />;
		} else
		if (formRow.type === 'select') {
			return (<Select placeholder={formRow.placeholder} {...formRow.options} />);
		} else
		if (formRow.type === 'user') {
			return <UserInput placeholder={formRow.placeholder} {...formRow.options} />;
		} else
		if (formRow.type === 'play') {
			return <PlayInput placeholder={formRow.placeholder} {...formRow.options} />;
		} else
		if (formRow.type === 'services') {
			return <ServicesEntityInput placeholder={formRow.placeholder} {...formRow.options} />
		} else
		if (formRow.type === 'cart') {
			return <CartInput placeholder={formRow.placeholder} {...formRow.options} />
		} else
		if (formRow.type === 'tags') {
			return <TagsInput placeholder={formRow.placeholder} {...formRow.options} />
		} else {
			throw new Error('Undefined control: ' + formRow.type);
		}
	}

	@autobind
	private renderRow(formRow: IFormRow, idx: number) {
		return (
			<Form.Item
				key={idx}
				label={formRow.type === 'richtext' ? (<>{formRow.label} <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'white', border: '1px solid #1890ff', fontSize: 10, paddingBottom: 0, marginLeft: 5, width: 15, height: 15, borderRadius: 7.5, }} href="#" onClick={e => { e.preventDefault(); this.help = true; }}>?</a></>) : formRow.label}
				name={formRow.field}
				rules={formRow.rules}
				tooltip={formRow.hint}
			>
				{this.renderControl(formRow)}
			</Form.Item>
		);
	}

	@observable help = false;

	mapValues(rawValues: any) {
		const r = this.props.valuesToFields ? this.props.valuesToFields(rawValues) : rawValues;
		const res: any = {};
		
		for (let row of this.props.form) {
			if (row.type === 'image' || row.type === 'audio') {
				res[row.field] =  r[row.field] ? {
					lastModified: Date.now(),
					lastModifiedDate: new Date(),
					name: "[Загруженный файл]",
					originFileObj: null,
					percent: 100,
					response: { result: true, data: { id: r[row.field] } },
					size: 1,
					status: "done",
					thumbUrl: row.type === 'image' ? gstore.api.fileLink(r[row.field]) : '',
					type: row.type === 'image' ? "image/jpeg" : 'audio/mpeg',
					uid: "rc-upload-" + Math.random(),
					xhr: null,
				} : null;
			} else
			if (row.type === 'date') {
				res[row.field] = r[row.field] && (moment(new Date(r[row.field])).format('YYYY-MM-DDTHH:mm'));
			} else {
				res[row.field] = row.rowValueToFieldValue ? row.rowValueToFieldValue(r[row.field]) : r[row.field];
			}
		}
		return res;
	}

	private renderForm() {
		return (
			<Form
				ref={this.formRef}
				layout="vertical"
				name={this.props.formName}
				onFinish={this.onFinish}
				initialValues={this.mapValues(this.props.initialValues || {})}
			>
				{this.error ? (<Alert style={{ marginBottom: 30 }} message={this.error} type="error" />) : null}

				{this.props.form.map(this.renderRow)}
			</Form>
		);
	}

	render() {

		return (
			<>
				{this.help ? (
					<Modal visible={this.help} title="Как форматировать текст" cancelText="Отмена" onCancel={() => this.help = false} onOk={() => this.help = false}>
						*жирный текст* = <b>жирный текст</b><br />
						~курсивный текст~ = <i>курсивный текст</i><br />
						#заголовок# = <span style={{ fontWeight: 'bold', fontSize: 18 }}>заголовок</span><br />
						[текст ссылки|https://google.com/] = <a href="https://google.com">текст ссылки</a><br />
						{'{'}красный{'}'} = <span style={{ color: '#6F2522' }}>красный</span><br />
						$синий$ = <span style={{ color: '#323C5A' }}>синий</span><br />
					</Modal>
				) : null}
				{this.renderForm()}
			</>
		);
	}
}

export default GenericForm;