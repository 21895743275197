import { observer } from "mobx-react";
import React, { PureComponent, ReactNode } from "react";
import { Icon, IconName, Spinner } from "@blueprintjs/core";
import cn from 'classnames';

import './style.scss';

export interface IFTRow extends Record<string, any> {

}

export interface IFTColumn {
	name: string;
	field: string;

	sorting?: 'asc' | 'desc';
	notSortable?: boolean;

	width?: number;
	weight?: number;

	renderHeader?: ReactNode | ((col: IFTColumn) => ReactNode);
	renderCell?: (value: any, row: IFTRow, rowIndex: number, col: IFTColumn, data: IFTRow[]) => ReactNode;
}

export interface IFTRowProps {
	row: IFTRow;
	rowIndex: number;
	data: IFTRow[];
	columns: IFTColumn[];
}

export interface IFTBodyProps {
	data: IFTRow[];
	columns: IFTColumn[];
}

export interface IFTableProps {
	data: IFTRow[];
	sorting?: null | { name: string, direction: 'asc' | 'desc' };
	onSorting?: (value: null | { name: string, direction: 'asc' | 'desc' }) => void;
	columns: IFTColumn[];
	loading?: boolean;
}

export interface IFTHeadProps {
	sorting?: null | { name: string, direction: 'asc' | 'desc' };
	onSorting?: (value: null | { name: string, direction: 'asc' | 'desc' }) => void;
	columns: IFTColumn[];
}

@observer
class FTRow extends PureComponent<IFTRowProps> {
	
	render() {
		const { columns, row, rowIndex, data } = this.props;

		return (
			<div className="ft-tr">
				{columns.map((col, idx) => {
					const value = row[col.field];
					const style: any = {};
					if (typeof col.width !== 'undefined') {
						style.flexBasis = col.width;
						style.flexGrow = 0;
						style.flexShrink = 0;
					} else
					if (typeof col.weight !== 'undefined') {
						style.flexBasis = 0;
						style.flexGrow = col.weight;
						style.flexShrink = col.weight;
					}
					return (
						<div className="ft-td" key={idx} style={style}>
							{col.renderCell
								? col.renderCell(value, row, rowIndex, col, data)
								: (<div className="ft-cell-value">{value}</div>)
							}
						</div>
					);
				})}
			</div>
		);
	}
}

@observer
class FTBody extends PureComponent<IFTBodyProps> {
	render() {
		const { data, columns } = this.props;

		return (
			<div className="ft-body">
				{data.map((row, idx) => (
					<FTRow key={idx} row={row} rowIndex={idx} data={data} columns={columns} />
				))}
			</div>
		);
	}
}

@observer
class FTHead extends PureComponent<IFTHeadProps> {
	
	render() {
		const { columns, sorting, onSorting } = this.props;

		console.log('header render: ', JSON.stringify(sorting));

		return (
			<div className="ft-head">
				<div className="ft-tr">
					{columns.map((col, idx) => {
						const style: any = {};
						if (typeof col.width !== 'undefined') {
							style.flexBasis = col.width;
							style.flexGrow = 0;
							style.flexShrink = 0;
						} else
						if (typeof col.weight !== 'undefined') {
							style.flexBasis = 0;
							style.flexGrow = col.weight;
							style.flexShrink = col.weight;
						}
						return (
							<div className={cn('ft-th', { 'not-sortable': col.notSortable })} key={idx} style={style} onClick={e => {
								e.preventDefault();
								if (col.notSortable || !onSorting) {
									return;
								}
								if (!sorting || sorting.name !== col.field) {
									onSorting({ name: col.field, direction: 'asc' });
								} else
								if (sorting.direction === 'asc') {
									onSorting({ name: col.field, direction: 'desc' });
								} else {
									onSorting(null);
								}
							}}>
								{col.renderHeader
									? (
										(typeof col.renderHeader === 'function')
											? col.renderHeader(col)
											: col.renderHeader
										)
									: (<div className="ft-cell-value">{col.name}</div>)
								}
								<div className="ft-cell-suffix">
									{(sorting && sorting.name === col.field) ? (
										<span className="sorting-dir">
											<Icon iconSize={13} icon={`sort-${sorting.direction}` as IconName} />
										</span>
									) : null}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

@observer
class FTable extends PureComponent<IFTableProps> {
	render() {
		const { data, columns, onSorting, sorting } = this.props;
		
		return (
			<div className={cn('ft-table-wrap', { loading: this.props.loading })}>
				<div className="ftable">
					<FTHead columns={columns} onSorting={onSorting} sorting={sorting} />
					<FTBody columns={columns} data={data} />
				</div>
			</div>
		);
	}
}

export default FTable;