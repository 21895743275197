import React, { PureComponent } from "react";

import { observer } from "mobx-react";

import moment from 'moment';

import swal from 'sweetalert';

import { SendOutlined } from '@ant-design/icons';

import gstore from "../../stores/gstore";
import EntityPage from "../../controls/EntityPage";
import { observable } from "mobx";
import { IWork } from "../../network/api";
import { autobind } from "core-decorators";

@observer
class WorksPage extends PureComponent<{}> {

	entityPageRef = React.createRef<EntityPage<IWork>>();

	@observable sendLoading: string | false = false;

	@autobind
	async handleSend(id: string) {
		this.sendLoading = id;

		const result = await gstore.api.sendWork(id);
		if (result.result) {
			swal({
				title: 'Успешно',
				text: 'Выслано ' + result.data.pushes + ' уведомлений'
			});
		}

		this.sendLoading = false;
		this.entityPageRef.current?.tableRef.load(this.entityPageRef.current?.tableRef.props);
	}

	render() {
		return (
			<EntityPage
				ref={this.entityPageRef}
				listEndpoint={`/work/admin/`}
				createEndpoint={gstore.api.addWork}
				editEndpoint={gstore.api.editWork}
				deleteEndpoint={gstore.api.deleteWork}
				createTitle="Создать уведомление"
				editTitle="Редактировать уведомление"
				search={true}
				formValuesToFields={raw => ({
					...raw,
					relatedServices: gstore.parseInlineIds(raw.relatedServices || '||'),
				})}
				formFieldsToValues={raw => ({
					...raw,
					relatedServices: gstore.buildInlineIds(raw.relatedServices || []),
				})}
				customRowActions={[
					(id) => ({
						onClick: this.handleSend,
						icon: <SendOutlined />,
						buttonProps: { loading: this.sendLoading === id, type: 'primary' }
					})
				]}
				form={[
					{
						type: 'string',
						field: 'name',
						label: 'Заголовок',
						rules: [{ required: true, message: 'Пожалуйста, введите заголовок' }]
					},
					{
						type: 'text',
						field: 'pushText',
						label: 'Текст для пуш-уведомления',
						placeholder: 'Текст для пуш-уведомления'
					},
					{
						type: 'date',
						field: 'delayTime',
						label: 'Дата запланированной отправки',
						placeholder: 'Дата запланированной отправки'
					},
				]}
				columns={[
					{
						name: 'Заголовок',
						field: 'name',
						weight: 1
					},
					{
						name: 'Текст',
						field: 'pushText',
						weight: 1
					},
					{
						name: 'Дата запланированной отправки',
						field: 'delayTime',
						weight: 1,
						renderCell: (value: string | null) => {
							return (
								value ? (
									moment(value).format('DD.MM.YYYY HH:mm')
								) : (
									<div className="muted">Нет</div>
								)
							);
						},
					},
					{
						name: 'Дата последней отправки',
						field: 'lastSend',
						weight: 1,
						renderCell: (value: string | null) => {
							return (
								value ? (
									moment(value).format('DD.MM.YYYY HH:mm')
								) : (
									<div className="muted">Никогда</div>
								)
							);
						},
					},
				]}
			/>
		);
	}
}

export default WorksPage;