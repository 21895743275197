import React, { PureComponent } from "react";

import { observer } from "mobx-react";
import TextArea from "antd/lib/input/TextArea";

export interface IRichTextInputProps {
	value?: string;
	onChange?: (value: string) => void;
}

const noParents = (a: any): any => {
	if (typeof a !== 'object') {
		return a;
	}

	if (Array.isArray(a)) {
		return a.map(t => noParents(t));
	} else {
		const na: any = {};
		for (let key in a) {
			if (key !== 'parent') {
				na[key] = noParents(a[key]);
			}
		}
		return na;
	}
}

export interface ISimplePart {
	type: 'simple';
	text: string;
}

export interface ISpecialPart {
	type: '*' | '#' | '~' | '[' | '{' | '$';
	parts: ITextPart[];
}

export type ITextPart = ISimplePart | ISpecialPart;

export interface ILinePart {
	type: 'line';
	parts: ITextPart[];
}

export const parseTextParts = (text: string): ILinePart[] => {
	const lines = text.split("\n");
	const lineParts = [];
	for (let line of lines) {
		let inEsc = false;
		let specs: string[] = [];
		let cp = '';
		let current = { parent: null as any, type: 'line', parts: [] as any[] };
		for (let i = 0; i < line.length; i++) {
			const c = line[i];
			if (inEsc) {
				cp += c;
				inEsc = false;
			} else
			if (c === '\\') {
				inEsc = true;
			} else
			if (c === '*' || c === '#' || c === '~' || c === '[' || c === ']' || c === '{' || c === '}' || c === '$') {
				if (specs.length && (specs[specs.length - 1] === c || ((specs[specs.length - 1] === '[') && (c === ']')) || ((specs[specs.length - 1] === '{') && (c === '}')))) {
					specs.pop();
					current.parts.push({ type: 'simple', text: cp });
					current = current.parent;
					cp = '';
				} else {
					if (cp) {
						current.parts.push({ type: 'simple', text: cp });
					}
					specs.push(c);
					const a = { parent: current, type: c, parts: [] };
					current.parts.push(a);
					current = a;
					cp = '';
				}
			} else {
				cp += c;
			}
		}
		if (cp) {
			current.parts.push({ type: 'simple', text: cp });
		}
		lineParts.push(current);
	}

	return noParents(lineParts);
}

interface IBuildOpts {
	Line: React.ElementType;
	Bold: React.ElementType;
	Italic: React.ElementType;
	Header: React.ElementType;
	Simple: React.ElementType;
	Red: React.ElementType;
	Blue: React.ElementType;
	Link: React.ElementType<{ url: string; }>;
}

export const buildTree = (lines: ILinePart[], opts: IBuildOpts) => {
	const { Line, Bold, Italic, Header, Simple, Link, Red, Blue } = opts;

	const mp = (a: ITextPart) => {
		if (a.type === 'simple') {
			return <Simple>{a.text}</Simple>
		} else
		if (a.type === '*') {
			return <Bold>{a.parts.map(p => mp(p))}</Bold>
		} else
		if (a.type === '~') {
			return <Italic>{a.parts.map(p => mp(p))}</Italic>
		} else
		if (a.type === '{') {
			return <Red>{a.parts.map(p => mp(p))}</Red>
		} else
		if (a.type === '$') {
			return <Blue>{a.parts.map(p => mp(p))}</Blue>
		} else
		if (a.type === '[') {
			//@ts-ignore
			const textAll = a.parts.filter(t => t.type === 'simple').map(t => t.text).join('');
			const url = textAll.split('|')[1] || '#';
			const text = textAll.split('|')[0];
			return <Link url={url}>{text}</Link>
		} else
		if (a.type === '#') {
			return <Header>{a.parts.map(p => mp(p))}</Header>
		}
	};

	return lines.map(line => <Line>{line.parts.map(p => mp(p))}</Line>);
}

const FRed = ({ children }: { children: any }) => <span style={{ color: '#6F2522' }}>{children}</span>;
const FBlue = ({ children }: { children: any }) => <span style={{ color: '#323C5A' }}>{children}</span>;

@observer
class RichTextInput extends PureComponent<IRichTextInputProps> {

	parseText(text: string): any {
		const lines = parseTextParts(text);
		const tree = buildTree(lines, {
			Line: 'p',
			Bold: 'b',
			Italic: 'i',
			Header: ({ children }) => <span style={{ fontWeight: 'bold', fontSize: 18 }}>{children}</span>,
			Simple: 'span',
			Red: FRed,
			Blue: FBlue,
			Link: ({ url, children }) => <a href={url}>{children}</a>
		})
		return <div>{tree}</div>;
	}

	render() {
		return (
			<>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
					<div style={{ flexGrow: 1, flexShrink: 1, flexBasis: 0}}>
						<TextArea value={this.props.value} onChange={e => this.props.onChange && this.props.onChange(e.target.value)} />
					</div>
					<div style={{ flexGrow: 1, flexShrink: 1, flexBasis: 0, padding: 5, border: '1px dotted #b0b0b0', marginLeft: 20 }}>
						{this.parseText(this.props.value || '')}
					</div>
				</div>
			</>
		);
	}
}

export default RichTextInput