import React, { PureComponent } from "react";

import { observer } from "mobx-react";

import { BoxPlotOutlined, DownOutlined, UpOutlined, DeleteOutlined, WarningOutlined } from '@ant-design/icons';

import Ava from "../../controls/Ava";

import gstore from "../../stores/gstore";
import EntityPage from "../../controls/EntityPage";
import { IFTColumn, IFTRow } from "../../controls/FTable";

import Modal from "antd/lib/modal/Modal";
import { observable } from "mobx";

import noImage from '../../noImage.png';

import './style.scss';
import { Button, Slider, Table, Upload } from "antd";
import { IPlayFullRecord, IPlayRecordImage } from "../../network/api";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { parseTextParts, buildTree } from "../../controls/RichTextInput";
import ButtonGroup from "antd/lib/button/button-group";

const isValidName = (name: string) => {
	const parts = name.split('.');
	if (parts.length < 4) {
		return false;
	} else {
		const ext = parts.pop();
		const seconds = parts.pop();
		const minutes = parts.pop();
		if (ext!.toLowerCase() !== 'jpg' && ext!.toLowerCase() !== 'jpeg') {
			return false;
		}
		//@ts-ignore
		if (isNaN(seconds) || parseInt(seconds, 10) < 0 || parseInt(seconds, 10) > 59) {
			return false;
		}
		//@ts-ignore
		if (isNaN(minutes) || parseInt(minutes, 10) < 0) {
			return false;
		}
	}
	return true;
}

@observer
class PlaysPage extends PureComponent<{}> {

	@observable playerVisible = false;
	@observable selectedTrack: any = null;
	@observable pos = 0;
	@observable changed = false;

	renderTime(v: number) {
		const m = Math.floor(v / 60);
		const s = Math.floor(v % 60);
		return `${m}:${(s < 10) ? ('0' + s) : s}`;
	}

	renderPlayerOld() {
		if (!this.selectedTrack) {
			return null;
		}

		let activeImage: IPlayRecordImage | null = null;
		const imgs = (this.selectedTrack.images as IPlayRecordImage[]).slice().sort((a, b) => {
			return a.timeStart - b.timeStart;
		});
		for (let i = 0; i < imgs.length; i++) {
			const im = imgs[i];
			if (i === 0) {
				activeImage = im;
			} else
			if (im.timeStart <= this.pos) {
				activeImage = im;
			}
		}

		return (
			<div className="plp">
				<div className="plp-curr-image">
					<div className="plp-img" style={{ backgroundImage: `url(${activeImage ? gstore.api.fileLink(activeImage.imageId) : noImage})` }}></div>
				</div>
				<div className="plp-slider">
					<div style={{ flexGrow: 0, flexShrink: 0, textAlign: 'center', flexBasis: 80 }}>
						0:00
					</div>
					<div style={{ flexGrow: 1, flexShrink: 1, textAlign: 'center', flexBasis: 0 }}>
						<Slider
							tipFormatter={v => this.renderTime(v || 0)}
							value={this.pos}
							max={Math.floor(this.selectedTrack.duration / 1000)}
							onChange={(e: number) => this.pos = e}
							marks={
								imgs.reduce((p: any, c: IPlayRecordImage) => {
									p[c.timeStart] = this.renderTime(c.timeStart);
									return p;
								}, {})
							}
						/>
					</div>
					<div style={{ flexGrow: 0, flexShrink: 0, textAlign: 'center', flexBasis: 80 }}>
						{this.renderTime(this.selectedTrack.duration / 1000)}
					</div>
				</div>
				<div className="plp-controls" style={{ flexGrow: 0, flexShrink: 0, textAlign: 'left', flexBasis: 80 }}>
					Текущая картинка: {activeImage ? this.renderTime(activeImage.timeStart) : 'Отсутствует'}
					{activeImage ? (
						<div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
							<Button danger={true} onClick={e => {
								const idx = this.selectedTrack.images.findIndex((t: IPlayRecordImage) => t.timeStart === activeImage!.timeStart);
								if (idx > -1) {
									this.selectedTrack.images.splice(idx, 1);
									this.changed = true;
								}
							}}>Удалить</Button>
						</div>
					) : null}
					{(!activeImage || activeImage.timeStart !== this.pos) ? (
						<div style={{ marginTop: 20 }}>
							<Upload
								accept="image/jpeg"
								action={`/api/files?entityType=play-image&entityId=${encodeURIComponent(this.selectedTrack.id)}`}
								onChange={(info: UploadChangeParam<UploadFile<any>>) => {
									if (info?.file?.response?.result) {
										this.selectedTrack.images.push({
											timeStart: this.pos,
											imageId: info.file.response.data.id,
											text: '',
										});
										this.changed = true;
										this.selectedTrack.images.sort((a: any, b: any) => {
											return a.timeStart - b.timeStart;
										});
									}
								}}
							>
								<Button type="primary">Добавить новую картинку на время {this.renderTime(this.pos)}</Button>
							</Upload>
						</div>
					) : null}
				</div>
			</div>
		);
	}

	@observable fileList: UploadFile[] = [];

	renderPlayer() {
		if (!this.selectedTrack) {
			return null;
		}

		const imgs = (this.selectedTrack.images as IPlayRecordImage[]).slice().sort((a, b) => {
			return a.timeStart - b.timeStart;
		});

		return (
			<div className="plp">
				{imgs.length ? (
					<table>
						<thead>
							<tr>
								<th style={{ paddingBottom: 15, width: '40%' }}>Изображение</th>
								<th style={{ paddingBottom: 15, width: '14%' }}>Время</th>
								<th style={{ paddingBottom: 15, width: '40%' }}>Имя файла</th>
								<th style={{ paddingBottom: 15, width: '6%' }}></th>
							</tr>
						</thead>
						<tbody>
							{imgs.map((i, idx) => (
								<tr style={{ borderBottom: '1px solid #e0e0e0' }}>
									<td style={{ paddingBottom: 6, paddingTop: 6, width: '40%' }}><img src={gstore.api.fileLink(i.imageId)} style={{ width: 200 }} /></td>
									<td style={{ paddingBottom: 6, paddingTop: 6, width: '14%' }}>{this.renderTime(i.timeStart)}</td>
									<td style={{ paddingBottom: 6, paddingTop: 6, width: '40%' }}>{i.filename}</td>
									<td style={{ paddingBottom: 6, paddingTop: 6, width: '6%' }}><Button onClick={() => {
										const idx = this.selectedTrack.images.findIndex((t: IPlayRecordImage) => t === i);
										if (idx > -1) {
											this.selectedTrack.images.splice(idx, 1);
											this.changed = true;
										}
									}}><DeleteOutlined /></Button></td>
								</tr>
							))}
						</tbody>
					</table>
				) : (
					<div style={{ textAlign: 'center', padding: 30 }}>Изображения отсутствуют</div>
				)}
				<div style={{ marginTop: 20 }}>
					<Upload
						fileList={this.fileList}
						multiple={true}
						accept="image/jpeg"
						action={`/api/files?entityType=play-image&entityId=${encodeURIComponent(this.selectedTrack.id)}`}
						beforeUpload={file => {
							return isValidName(file.name);
						}}
						itemRender={(originNode, file, fileList) => (
							<div style={{ color: (file.percent === 0 && !isValidName(file.name)) ? 'red' : void 0 }}>{originNode} {(file.percent === 0 && !isValidName(file.name)) ? '[Некорректное название]' : null}</div>
						)}
						onChange={(info: UploadChangeParam<UploadFile<any>>) => {
							console.log('info: ', info.fileList);
							this.fileList = info.fileList;

							if (this.fileList.length && this.fileList.every(file => file.percent === 100 || (file.percent === 0 && !isValidName(file.name)))) {
								setTimeout(() => {
									const c = this.fileList.filter(f => f.percent === 100 && f.response?.result === true);
									for (let t of c) {
										const parts = t.name.split('.');
										const ext = parts.pop();
										const seconds = parts.pop();
										const minutes = parts.pop();
										this.selectedTrack.images.push({
											filename: parts.join(''),
											timeStart: parseInt(minutes!, 10) * 60 + parseInt(seconds!, 10),
											imageId: t.response.data.id,
											text: '',
										});
									}
									this.changed = true;
									this.selectedTrack.images.sort((a: any, b: any) => {
										return a.timeStart - b.timeStart;
									});
									this.fileList = [];
								}, 2000);
							}
						}}
					>
						<Button type="primary">Загрузить новые изображения</Button>
					</Upload>
					<br />
					<Button danger={true} onClick={() => {
						this.selectedTrack.images = [];
					}}>Удалить все изображения</Button>
					<div style={{ marginTop: 20 }}>
						<WarningOutlined color="red" style={{ color: 'red', }} />&nbsp;&nbsp;Название каждого файла должено соответствовать форме:
					</div>
					<div style={{ textAlign: 'center', padding: 20, fontSize: 16 }}>
						<b>[Название].[Минуты].[Секунды].jpg</b>
					</div>
					<div>Пример: <b>АфишаВторогоАкта.3.15.jpg</b> - Изображение отобразится на 3 минуте, 15 секунде трека.</div>
					<div>Примерные размеры изображения: 1080х1600px</div>
				</div>
			</div>
		);
	}

	ep = React.createRef<EntityPage<IPlayFullRecord>>();

	render() {
		return (
			<>
				<Modal
					maskClosable={false}
					title={<div>Редактирование слайд-шоу трека</div>}
					centered
					visible={this.playerVisible}
					onOk={async () => {
						if (this.changed) {
							await gstore.api.editPlay({
								id: this.selectedTrack.id,
								images: this.selectedTrack.images
							}).then(_ => {
								this.playerVisible = false;
							});
						}
					}}
					cancelText="Отмена"
					onCancel={() => this.playerVisible = false}
					okText="Сохранить"
					width={600}
				>
					{this.renderPlayer()}
				</Modal>
				<EntityPage
					ref={this.ep}
					listEndpoint={`/plays/admin/`}
					createEndpoint={gstore.api.addPlay}
					editEndpoint={gstore.api.editPlay}
					deleteEndpoint={gstore.api.deletePlay}
					createTitle="Создать спектакль"
					editTitle="Редактировать спектакль"
					customRowActions={[
						(id: string, row: IFTRow) => ({
							onClick: (id: string, row: IFTRow) => {
								this.selectedTrack = row;
								this.changed = false;
								this.playerVisible = true;
							},
							icon: <BoxPlotOutlined />,
							buttonProps: {
							},
							tooltip: 'Слайд-шоу'
						})
					]}
					form={[
						{
							type: 'string',
							field: 'title',
							label: 'Название спектакля',
							rules: [{ required: true, message: 'Пожалуйста, введите название спектакля' }]
						},
						{
							type: 'audio',
							field: 'audioFileId',
							label: 'Аудиофайл',
							options: {
								entityType: 'play',
								// entityId={this.placeId}
								accept: 'audio/mpeg',
							},
							rules: [{ required: true, message: 'Пожалуйста, загрузите аудиофайл' }]
						},
						{
							type: 'image',
							field: 'mainImageId',
							label: 'Афиша спектакля',
							options: {
								entityType: 'play-title',
								accept: 'image/jpeg',
								// entityId={this.placeId}
							},
							rules: [{ required: true, message: 'Пожалуйста, загрузите афишу спектакля' }],
							hint: 'Размер: 600x420, формат: jpeg/png',
						},
						{
							type: 'richtext',
							field: 'description',
							label: 'Описание для экрана "Репертуар"',
							rules: [{ required: true, message: 'Пожалуйста, введите описание' }]
						},
						{
							type: 'richtext',
							field: 'textRep',
							label: 'Описание для экрана "Репертуар.Спектакль"',
							rules: [{ required: true, message: 'Пожалуйста, введите описание' }]
						},
						{
							type: 'richtext',
							field: 'textMine',
							label: 'Описание для экрана "Мои спектакли"',
						},
						{
							type: 'richtext',
							field: 'textPlayer',
							label: 'Описание для экрана "Плеер"',
						},
						{
							type: 'number',
							field: 'price',
							label: 'Цена (в руб), минимум 15',
						},
						{
							type: 'select',
							field: 'visibility',
							label: 'Видимость',
							options: {
								options: [
									{ value: 'none', label: 'Нигде' },
									{ value: 'onlyBought', label: 'Только купившие' },
									{ value: 'repertoir', label: 'Репертуар' },
									{ value: 'announce', label: 'Анонс' },
								]
							}
						},
					]}
					columns={observable([
						{
							name: 'Порядок',
							field: 'sorting',
							weight: 0.3,
							notSortable: true,
							renderCell: (value: any, row: IFTRow, rowIndex: number, col: IFTColumn, data: IFTRow[]) => {
								return (
									<>
										<ButtonGroup>
											<Button
												size="small"
												disabled={rowIndex === 0}
												onClick={async (e) => {
													e.preventDefault();
													const myId = row.id;
													const targetId = data[rowIndex - 1].id;
													await gstore.api.sortPlays(myId, targetId);
													await this.ep.current!.tableRef!.load(this.ep.current!.tableRef!.props);
												}}
											>
													<UpOutlined />
												</Button>
											<Button
												size="small"
												disabled={rowIndex === data.length - 1}
												onClick={async (e) => {
													e.preventDefault();
													const myId = row.id;
													const targetId = data[rowIndex + 1].id;
													await gstore.api.sortPlays(myId, targetId);
													await this.ep.current!.tableRef!.load(this.ep.current!.tableRef!.props);
												}}
											>
													<DownOutlined />
												</Button>
										</ButtonGroup>
									</>
								);
							}
						},
						{
							name: 'Название спектакля',
							field: 'title',
							weight: 1
						},
						{
							name: 'Цена',
							field: 'price',
							weight: 0.4,
							renderCell: (value: string) => {
								return <div>{parseFloat(value).toFixed(2)} руб.</div>;
							}
						},
						{
							name: 'Описание для экрана "Репертуар"',
							field: 'description',
							weight: 2,
							renderCell: (value: string) => {
								const lines = parseTextParts(value);
								const tree = buildTree(lines, {
									Line: 'div',
									Bold: 'b',
									Italic: 'i',
									Header: ({ children }) => <span style={{ fontWeight: 'bold', fontSize: 18 }}>{children}</span>,
									Simple: 'span',
									Red: ({ children }: { children: any }) => <span style={{ color: '#6F2522' }}>{children}</span>,
									Blue: ({ children }: { children: any }) => <span style={{ color: '#323C5A' }}>{children}</span>,
									Link: ({ url, children }) => <a href={url}>{children}</a>,
								})
								return <div>{tree}</div>;
							}
						},
						{
							name: 'Афиша спектакля',
							field: 'mainImageId',
							width: 140,
							renderCell: (value: string | null) => {
								return (
									value ? (
										<Ava src={gstore.api.fileLink(value)} />
									) : (
										<div className="muted">Пусто</div>
									)
								);
							},
						},
					])}
				/>
			</>
		);
	}
}

export default PlaysPage;