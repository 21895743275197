import React, { PureComponent } from "react";

import { notification, Select } from "antd";
import gstore from "../../stores/gstore";
import { observer } from "mobx-react";
import MultipleEntityInput, { IMultipleEntityInputProps } from "../MultipleEntityInput";

import './style.scss';
import Ava from "../Ava";

const { Option } = Select;

@observer
class ServicesEntityInput extends PureComponent<Omit<IMultipleEntityInputProps, 'action' | 'renderSelectedItem' | 'columns'>> {
	render() {
		return (
			<MultipleEntityInput
				action={async (ids, query) => {
					const res = await gstore.api.multipleSearch('service', ids, query);
					if (res.result) {
						return res.data;
					} else {
						notification.error({
							message: 'Произошла ошибка при загрузке записей'
						});
						return [];
					}
				}}
				renderSelectedItem={item => (
					<div className="service-row">
						<Ava src={gstore.api.fileLink(item.payload.imageId)} small style={{ marginRight: 10 }} />
						<div className="service-row-title"><span>{item.text}</span></div>
					</div>
				)}
				columns={[
					{
						name: 'Название',
						field: 'title',
						weight: 1
					},
					{
						name: 'Фото',
						field: 'imageId',
						width: 110,
						renderCell: (value: string | null) => {
							return (
								value ? (
									<Ava src={gstore.api.fileLink(value)} small />
								) : (
									<div className="muted">Пусто</div>
								)
							);
						},
					},
				]}
				{...this.props}
			/>
		);
	}
}

export default ServicesEntityInput