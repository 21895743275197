import React, { PureComponent } from 'react';

import { observable } from 'mobx';
import { observer } from 'mobx-react';

import './App.scss';

import gstore from './stores/gstore';
import Centroid from './controls/Centroid';
import { Spin } from 'antd';
import MainPage from './pages/MainPage';
import AuthPage from './pages/AuthPage';
import Modal from 'antd/lib/modal/Modal';

@observer
class App extends PureComponent {

	@observable isLoading = true;

	async componentDidMount() {
		await gstore.init();
		this.isLoading = false;
	}

	render() {
		if (gstore.globalError) {
			return <div className="global-error">{gstore.globalError}</div>
		}

		return (
			<div className="app">
				{this.isLoading ? <Centroid><Spin /></Centroid> : (
					gstore.me ? <MainPage /> : <AuthPage />
				)}
			</div>
		);
	}
}

export default App;
