import React, { PureComponent } from "react";

import { observable } from "mobx";
import { observer } from "mobx-react";

import { autobind } from "core-decorators";
import { v4 as uuidv4 } from 'uuid';

import Modal from "antd/lib/modal/Modal";

import { IResponse } from "../../network/api";
import { IFTRow } from "../../controls/FTable";
import TablePage, { ITablePageProps } from "../../controls/TablePage";
import GenericForm, { FormSubmitResult, IFormRow } from "../GenericForm";
import gstore from "../../stores/gstore";

export interface IEntityPageProps<T> extends Omit<ITablePageProps<T>, 'onCreate' | 'onEdit' | 'onDelete'> {
	form: IFormRow[];

	formValuesToFields?: (rawValues: any) => any;
	formFieldsToValues?: (rawValues: any) => any;

	createEndpoint?: (data: T) => Promise<IResponse<{id: string}>>;
	editEndpoint?: (data: Partial<T> & { id: string }) => Promise<IResponse<{id: string}>>;
	deleteEndpoint?: (id: string) => Promise<IResponse<void>>;

	createTitle?: string;
	editTitle?: string;
}

@observer
class EntityPage<T extends Record<string, any>> extends PureComponent<IEntityPageProps<T>> {

	tableRef!: TablePage<any>;

	createFormRef = React.createRef<GenericForm>();
	editFormRef = React.createRef<GenericForm>();

	@observable entityId = 'null';
	@observable entity: T | null = null;

	@observable createModalVisible = false;
	@observable createError = '';
	@observable createLoading = false;

	@observable editModalVisible = false;
	@observable editError = '';
	@observable editLoading = false;

	@autobind
	async onSubmitCreateForm(data: any): Promise<FormSubmitResult> {
		const postData: any = {
			id: this.entityId
		};
		for (let key in data) {
			const row = this.props.form.find(f => f.field === key);
			if (row) {
				if (row.fieldValueToEntityValue) {
					postData[row.field] = row.fieldValueToEntityValue(data[row.field]);
				} else {
					postData[row.field] = data[row.field];
				}
			} else {
				postData[key] = data[key];
			}
		}
		
		this.createLoading = true;
		try {
			const createResult = await this.props.createEndpoint!(postData);
			if (!createResult.result) {
				this.createLoading = false;
				return { result: false, error: createResult.error };
			}
		} catch (err) {
			console.log('error: ', err);
			this.createLoading = false;
			return { result: false, error: err.message };
		}
		this.createLoading = false;

		await this.tableRef.load(this.tableRef.props);
		this.createModalVisible = false;
		return { result: true };
	};

	@autobind
	async onSubmitEditForm(data: any): Promise<FormSubmitResult> {
		const postData: any = {
			id: this.entityId
		};

		for (let key in data) {
			const row = this.props.form.find(f => f.field === key);
			if (row) {
				if (row.fieldValueToEntityValue) {
					postData[row.field] = row.fieldValueToEntityValue(data[row.field]);
				} else {
					postData[row.field] = data[row.field];
				}
			} else {
				postData[key] = data[key];
			}
		}

		this.editLoading = true;
		try {
			const editResult = await this.props.editEndpoint!(postData);
			if (!editResult.result) {
				this.editLoading = false;
				return { result: false, error: editResult.error };
			}
		} catch (err) {
			console.log('error: ', err);
			this.editLoading = false;
			return { result: false, error: err.message };
		}
		this.editLoading = false;

		await this.tableRef.load(this.tableRef.props);
		this.editModalVisible = false;
		return { result: true };
	};

	@autobind
	handleEdit(id: string, row: IFTRow) {
		this.entityId = id;
		this.entity = row as T;
		this.editModalVisible = true;
	}

	@autobind
	async handleDelete(id: string) {
		await this.props.deleteEndpoint!(id);
	}

	@autobind
	handleCreate() {
		this.entityId = uuidv4();
		this.createModalVisible = true;
	}

	private renderCreateModal() {
		return (
			<Modal
				maskClosable={false}
				title={this.props.createTitle}
				confirmLoading={this.createLoading}
				centered
				visible={this.createModalVisible}
				onOk={() => {
					if (!this.createFormRef.current) {
						return;
					}
					this.createFormRef.current.submit();
				}}
				cancelText="Отмена"
				onCancel={() => this.createModalVisible = false}
				width={600}
			>
				<GenericForm
					ref={this.createFormRef}
					form={this.props.form}
					formName="activeForm"
					fileEntityId={this.entityId}
					valuesToFields={this.props.formValuesToFields}
					fieldsToValues={this.props.formFieldsToValues}
					onSubmit={this.onSubmitCreateForm}
				/>
			</Modal>
		);
	}

	private renderEditModal() {
		if (!this.entity) {
			return null;
		}

		return (
			<Modal
				maskClosable={false}
				title={`${this.props.editTitle}${(this.entityId && this.entityId !== 'null') ? (' - ' + this.entityId) : ''}`}
				centered
				visible={this.editModalVisible}
				onOk={() => {
					if (!this.editFormRef.current) {
						return;
					}
					this.editFormRef.current.submit();
				}}
				cancelText="Отмена"
				confirmLoading={this.editLoading}
				onCancel={() => this.editModalVisible = false}
				width={600}
			>
				<GenericForm
					ref={this.editFormRef}
					form={this.props.form}
					formName="activeForm"
					fileEntityId={this.entityId}
					valuesToFields={this.props.formValuesToFields}
					fieldsToValues={this.props.formFieldsToValues}
					onSubmit={this.onSubmitEditForm}
					initialValues={this.entity}
				/>
			</Modal>
		);
	}

	render() {

		return (
			<>
				{this.createModalVisible ? this.renderCreateModal() : null}
				{this.editModalVisible ? this.renderEditModal() : null}
				<TablePage
					ref={ref => ref && (this.tableRef = ref)}
					listEndpoint={this.props.listEndpoint}
					onCreate={this.props.createEndpoint ? this.handleCreate : void 0}
					onEdit={this.props.editEndpoint ? this.handleEdit : void 0}
					onDelete={this.props.deleteEndpoint ? this.handleDelete : void 0}
					columns={this.props.columns}
					customRowActions={this.props.customRowActions}
					customGlobalActions={this.props.customGlobalActions}
					extras={this.props.extras}
					search={this.props.search}
				/>
			</>
		);
	}
}

export default EntityPage;