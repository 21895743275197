import React, { PureComponent } from "react";

import { notification, Select } from "antd";
import gstore from "../../stores/gstore";
import { observer } from "mobx-react";
import MultipleEntityInput, { IMultipleEntityInputProps } from "../MultipleEntityInput";

import './style.scss';
import Ava from "../Ava";
import { ICartItem } from "../../network/api";

const { Option } = Select;

@observer
class CartInput extends PureComponent<Omit<IMultipleEntityInputProps, 'value' | 'action' | 'renderSelectedItem' | 'columns' | 'onChange'> & { value?: ICartItem[], onChange?: (newVal: ICartItem[]) => void }> {

	meiRef = React.createRef<MultipleEntityInput>();

	render() {
		const { value, onChange, ...rest } = this.props;

		return (
			<MultipleEntityInput
				ref={this.meiRef}
				value={(value || []).map(v => v.itemId)}
				action={async (ids, query) => {
					const res = await gstore.api.multipleSearch('service', ids, query);
					if (res.result) {
						return res.data;
					} else {
						notification.error({
							message: 'Произошла ошибка при загрузке записей'
						});
						return [];
					}
				}}
				onChange={ids => {
					if (onChange) {
						onChange(ids.map(id => {
							const c = this.meiRef.current!.cache[id]!;
							return {
								itemId: id,
								itemTitle: c.text,
								itemImageId: c.payload.imageId,
								amount: value?.find(i => i.itemId === id)?.amount || 0,
								address: value?.find(i => i.itemId === id)?.address || '',
								placeId: null
							};
						}));
					}
				}}
				renderSelectedItem={item => (
					<div className="service-row service-row-col">
						<Ava src={gstore.api.fileLink(item.payload.imageId)} small style={{ marginRight: 10 }} />
						<div className="service-row-content">
							<div className="service-row-top">
								<div className="service-row-title"><span>{item.text}</span></div>
							</div>
							<div className="service-row-bottom">
								Количество: {value?.find(i => i.itemId === item.id)?.amount}<br />
								Адрес: {value?.find(i => i.itemId === item.id)?.address}
							</div>
						</div>
					</div>
				)}
				columns={[
					{
						name: 'Название',
						field: 'title',
						weight: 1
					},
					{
						name: 'Фото',
						field: 'imageId',
						width: 110,
						renderCell: (value: string | null) => {
							return (
								value ? (
									<Ava src={gstore.api.fileLink(value)} small />
								) : (
									<div className="muted">Пусто</div>
								)
							);
						},
					},
				]}
				{...rest}
			/>
		);
	}
}

export default CartInput