import React, { PureComponent } from "react";

import { PlusCircleOutlined, DownloadOutlined } from '@ant-design/icons';

import { observer } from "mobx-react";

import gstore from "../../stores/gstore";
import EntityPage from "../../controls/EntityPage";
import moment from "moment";
import { observable } from "mobx";
import Modal from "antd/lib/modal/Modal";
import Form, { FormInstance } from "antd/lib/form";
import { autobind } from "core-decorators";
import { v4 as uuidv4 } from 'uuid';
import PlayInput from "../../controls/PlayInput";
import { InputNumber } from "antd";
import { IContact } from "../../network/api";

@observer
class ContactsPage extends PureComponent<{}> {

	@observable multipleVisible = false;

	formRef = React.createRef<FormInstance>();
	entityRef = React.createRef<EntityPage<IContact>>();

	@observable error = '';
	@observable loading = false;
	@observable created = 0;
	@observable needCount = 0;

	submit() {
		this.formRef.current?.submit();
	}

	generateCode() {
		const alp = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
		const l = 10;
		let s = '';
		while (s.length !== l) {
			s += alp[Math.floor(Math.random() * alp.length)];
		}
		return s;
	}

	@autobind
	async onFinish(data: any) {
		this.created = 0;
		this.needCount = data.needCount;
		this.loading = true;
		for (let i = 0; i < data.needCount; i++) {
			try {
				const res = await gstore.api.addContact({
					id: uuidv4(),
					playId: data.playId,
					name: this.generateCode(),
					useTimestamp: null,
				});
				if (!res.result) {
					this.error = res.error;
				} else {
					this.created++;
				}
			} catch (err) {
				console.log('error: ', err);
				this.loading = false;
				this.error = 'Form error';
				return;
			}
		}
		this.loading = false;

		this.multipleVisible = false;
		this.entityRef.current!.tableRef.load(this.entityRef.current!.tableRef.props);
	}

	renderMultiple() {
		return (<>
			<Form
				ref={this.formRef}
				layout="vertical"
				name="form"
				onFinish={this.onFinish}
				initialValues={{
					playId: null,
					needCount: 10,
				}}
			>
				<Form.Item
					label="Спектакль"
					name="playId"
				>
					<PlayInput />
				</Form.Item>
				<Form.Item
					label="Количество"
					name="needCount"
				>
					<InputNumber min={1} />
				</Form.Item>
			</Form>
		</>);
	}

	render() {
		return (
			<>
				<Modal
					maskClosable={false}
					title="Создать несколько промокодов"
					centered
					visible={this.multipleVisible}
					onOk={async () => {
						this.submit();
					}}
					cancelText="Отмена"
					onCancel={() => this.multipleVisible = false}
					okText={this.loading ? `Создано ${this.created} / ${this.needCount}...` : "Создать"}
					okButtonProps={{
						loading: this.loading,
						disabled: this.loading
					}}
					width={600}
				>
					{this.renderMultiple()}
				</Modal>
				<EntityPage
					ref={this.entityRef}
					listEndpoint={`/contact/admin/`}
					createEndpoint={gstore.api.addContact}
					editEndpoint={gstore.api.editContact}
					deleteEndpoint={gstore.api.deleteContact}
					search={true}
					createTitle="Создать промокод"
					editTitle="Редактировать промокод"
					customGlobalActions={[
						{
							onClick: () => {
								this.multipleVisible = true;
							},
							icon: <><PlusCircleOutlined /></>,
							text: 'Создать несколько',
						},
						{
							onClick: () => {
								window.open(gstore.api.getCsvUrl());
							},
							icon: <><DownloadOutlined /></>,
							text: 'Скачать XLSX',
						}
					]}
					form={[
						{
							type: 'string',
							field: 'name',
							label: 'Код',
							rules: [{ required: true, message: 'Пожалуйста, введите код' }]
						},
						{
							type: 'play',
							field: 'playId',
							label: 'Спектакль',
						},
					]}
					columns={[
						{
							name: 'Спектакль',
							field: 'play',
							weight: 1,
							renderCell: (value: any) => {
								return value ? <span>{value.title}</span> : <span style={{ opacity: 0.9 }}>Любой</span>;
							}
						},
						{
							name: 'Код',
							field: 'name',
							weight: 1,
						},
						{
							name: 'Когда использован',
							field: 'useTimestamp',
							weight: 1,
							renderCell: (value: number | null) => {
								return value ? <span>{moment.unix(value).format('DD.MM.YYYY HH:m')}</span> : <span>Нет</span>;
							}
						},
						{
							name: 'Кем использован',
							field: 'userEmail',
							weight: 1,
							renderCell: (value: string | null) => {
								return value ? <span>{value}</span> : <span>Нет</span>;
							}
						},
						{
							name: 'Создан',
							field: 'createdAt',
							weight: 1,
							renderCell: (value: any) => {
								return moment(value).format('DD.MM.YYYY HH:mm');
							}
						},
					]}
				/>
			</>
		);
	}
}

export default ContactsPage;