import React, { ComponentProps, PureComponent, ReactNode } from "react";

import { Button, Form, Upload } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { autobind } from "core-decorators";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import gstore from "../../stores/gstore";
import { UploadFile } from "antd/lib/upload/interface";

export interface IFileInputProps {
	value?: any;
	onChange?: (data: any) => void;
	entityType: 'order' | 'place' | 'user' | 'service' | 'license';
	entityId: string;
	accept?: string;
}

const normFile = (e: any) => {
	if (Array.isArray(e)) {
		return e;
	}

	return e && e.fileList;
};

const ForwardComponent: <T extends React.ElementType>(props: ComponentProps<T & any> & { Component: T, inner: (p: any) => ComponentProps<T> }) => any = ({ Component, inner, ...props }) => (
	<Component {...props} {...inner(props)} />
)

class FileInput extends PureComponent<IFileInputProps> {

	@autobind
	async handleUpload(file: RcFile): Promise<string> {
		return `/api/files?entityType=${this.props.entityType}&entityId=${encodeURIComponent(this.props.entityId)}`;
	}

	render() {
		console.log('render file input: ', this.props);
		return (
			<ForwardComponent
				Component={Upload}
				multiple={false}
				name="file"
				action={this.handleUpload}
				listType="picture"
				accept={this.props.accept}
				onChange={this.props.onChange}
				value={this.props.value}
				inner={(props: any) => ({
					onChange: (info: UploadChangeParam<UploadFile<any>>) => {
						if (props.onChange) {
							props.onChange(normFile(info)[0]);
						}
					},
					fileList: props.value ? [props.value] : [],
				})}
			>
				<Button icon={<UploadOutlined />}>Нажмите для загрузки</Button>
			</ForwardComponent>
		);
	}
}

export default FileInput