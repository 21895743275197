import { autobind } from 'core-decorators';
import querystring from 'querystring';
import { ISimpleQueryActionResult } from '../controls/SingleEntityInput';

export interface IServiceItem {
	id: string;
	isService: boolean;
	category: string | null;
	title: string;
	description: string;
	imageId: string | null;
	price: number;
}

export type IResponse<T> = {
	result: false,
	error: string,
	data?: T
} | {
	result: true,
	data: T
};

export interface IPlayRecordImage {
	timeStart: number;
	filename: string;
	imageId: string;
	text: string;
}

export interface IPlayFullRecord {
	id: string;
	title: string;
	audioFileId: string;
	duration: number;
	description: string;
	price: number;
	instagram: string;
	visibility: 'repertoir' | 'onlyBought' | 'none';

	mainImageId: string;
	textMine: string;
	textPlayer: string;
	textRep: string;

	images: IPlayRecordImage[];
}

export interface ICartItem {
	itemId: string;
	itemTitle: string;
	itemImageId: string | null;
	amount: number;
	address: string;
	placeId: string | null;
}

export interface ICartContent {
	type: 'cart',
	cart: ICartItem[];
	description: string;
}

export interface ISimpleContent {
	type: 'simple',
	description: string;
}

export interface IOrder {
	id: string;
	createdByUserId: string;
	activeExecutorId: string | null;
	title: string;
	content: ICartContent | ISimpleContent;
	state: 'created' | 'executing' | 'done' | 'cancelled';
	tags: string;
}

export interface IPlace {
	id: string;
	userId: string;
	name: string;
	adminComment: string;
	contacts: string;
	description: string;
	address: string;
	mainPhotoId: string | null;
}

export interface IKV {
	id: string;
	value: string;
}

export interface ICat {
	id: string;
	name: string;
}

export interface ILicense {
	id: string;
	name: string;
	mainPhotoId: string;
}

export interface IContact {
	id: string;
	name: string;
	playId: string | null;
	useTimestamp: number | null;
}

export interface IWork {
	id: string;
	name: string;
	text: string;
	pushText: string;
	relatedServices: string;
	lastSend: Date | null;
}

export interface IService {
	id: string;
	isService: boolean;
	category: string | null;
	title: string;
	description: string;
	imageId: string | null;
	price: number;
	tags: string;
}

export interface IFileData {
	response: { data: { id: string }};
}

class API {

	host: string;

	constructor(host: string) {
		this.host = host;
	}

	get<T extends {}>(url: string, qs?: Record<string, string>): Promise<T> {
		return fetch(this.host + url + (qs ? ('?' + querystring.stringify(qs)) : ''), {
			method: 'GET',
			credentials: 'include',
			cache: "no-cache",
		}).then(response => {
			return response.json() as unknown as T;
		});
	}

	post<T extends {}>(url: string, data?: FormData | Record<string, any>, qs?: Record<string, string>): Promise<T> {
		const headers: any = {};
		const opts: any = {
			method: 'POST',
			credentials: 'include',
		};
		if (data) {
			if (data instanceof FormData) {
				opts.body = data;
			} else {
				headers['Content-Type'] = 'application/json';
				opts.body = JSON.stringify(data)
			}
		}
		opts.headers = headers;
		return fetch(this.host + url + (qs ? ('?' + querystring.stringify(qs)) : ''), opts).then(response => {
			return response.json() as unknown as T;
		});
	}

	getCsvUrl() {
		return this.host + '/contact/csv';
	}

	getReportUrl(qs: Record<string, string>) {
		return this.host + '/plays/csv?' + querystring.stringify(qs);
	}

	@autobind
	async getList<T extends {}>(url: string, page: number, rowsPerPage: number, qs?: Record<string, string>) {
		return this.get<IResponse<{ list: T[], total: number }>>(url, { page: String(page), rowsPerPage: String(rowsPerPage), ...qs });
	}

	@autobind
	async me() {
		return await this.get<IResponse<{ id: string, email: string, role: string } | null>>('/auth/me');
	}

	@autobind
	async tags() {
		return await this.get<IResponse<{ id: string, name: string }[]>>('/auth/tags');
	}

	@autobind
	async sortPlays(myId: string, targetId: string) {
		return await this.post<IResponse<void>>('/plays/sort', { myId, targetId });
	}

	@autobind
	async getExecutors(tags: string[]) {
		return await this.post<IResponse<ISimpleQueryActionResult[]>>('/order/getExecutors', { tags });
	}

	@autobind
	async login(email: string, password: string) {
		return await this.post<IResponse<void>>('/auth/login', { email, password });
	}

	@autobind
	async logout() {
		return await this.post<IResponse<void>>('/auth/logout', { });
	}

	@autobind
	async listServices() {
		return await this.get<IResponse<IServiceItem[]>>('/service/');
	}

	// ----

	@autobind
	async addUser(data: { id: string, role: 'user' | 'executor' | 'admin', tags: string, isActive: boolean, email: string, password: string, name: string, photoId: string | null }) {
		return await this.post<IResponse<{ id: string }>>('/user/', data);
	}

	@autobind
	async editUser(data: { id: string } & Partial<{ boughtPlays: any; tags: string, isActive: boolean, email: string, password?: string, name: string, photoId?: string | null }>) {
		return await this.post<IResponse<{ id: string }>>('/user/edit', data);
	}

	@autobind
	async deleteUser(id: string) {
		return await this.post<IResponse<void>>('/user/delete', { id });
	}

	// ----

	@autobind
	async addPlace(data: IPlace) {
		return await this.post<IResponse<{ id: string }>>('/place/', data);
	}

	@autobind
	async editPlace(data: Partial<IPlace>) {
		return await this.post<IResponse<{ id: string }>>('/place/edit', data);
	}

	@autobind
	async deletePlace(id: string) {
		return await this.post<IResponse<void>>('/place/delete', { id });
	}

	// ----

	@autobind
	async addKV(data: IKV) {
		return await this.post<IResponse<{ id: string }>>('/kvs/', data);
	}

	@autobind
	async editKV(data: Partial<IKV>) {
		return await this.post<IResponse<{ id: string }>>('/kvs/edit', data);
	}

	@autobind
	async deleteKV(id: string) {
		return await this.post<IResponse<void>>('/kvs/delete', { id });
	}

	// ----

	@autobind
	async addCat(data: ICat) {
		return await this.post<IResponse<{ id: string }>>('/cats/', data);
	}

	@autobind
	async editCat(data: Partial<ICat>) {
		return await this.post<IResponse<{ id: string }>>('/cats/edit', data);
	}

	@autobind
	async deleteCat(id: string) {
		return await this.post<IResponse<void>>('/cats/delete', { id });
	}

	// ----

	@autobind
	async addPlay(data: IPlayFullRecord) {
		return await this.post<IResponse<{ id: string }>>('/plays/', data);
	}

	@autobind
	async editPlay(data: Partial<IPlayFullRecord>) {
		return await this.post<IResponse<{ id: string }>>('/plays/edit', data);
	}

	@autobind
	async deletePlay(id: string) {
		return await this.post<IResponse<void>>('/plays/delete', { id });
	}

	// ----

	@autobind
	async addContact(data: IContact) {
		return await this.post<IResponse<{ id: string }>>('/contact/', data);
	}

	@autobind
	async editContact(data: Partial<IContact>) {
		return await this.post<IResponse<{ id: string }>>('/contact/edit', data);
	}

	@autobind
	async deleteContact(id: string) {
		return await this.post<IResponse<void>>('/contact/delete', { id });
	}

	// ----

	@autobind
	async addWork(data: Omit<IWork, 'lastSend'>) {
		return await this.post<IResponse<{ id: string }>>('/work/', data);
	}

	@autobind
	async editWork(data: Partial<IWork>) {
		return await this.post<IResponse<{ id: string }>>('/work/edit', data);
	}

	@autobind
	async sendWork(id: string) {
		return await this.post<IResponse<{ sent: number, pushes: number }>>('/work/send', { id });
	}

	@autobind
	async deleteWork(id: string) {
		return await this.post<IResponse<void>>('/work/delete', { id });
	}

	// ----

	@autobind
	async addService(data: Omit<IService, 'lastSend'>) {
		return await this.post<IResponse<{ id: string }>>('/service/', data);
	}

	@autobind
	async editService(data: Partial<IService>) {
		return await this.post<IResponse<{ id: string }>>('/service/edit', data);
	}

	@autobind
	async deleteService(id: string) {
		return await this.post<IResponse<void>>('/service/delete', { id });
	}

	// ----

	@autobind
	async addOrder(data: Omit<IOrder, 'tags'>) {
		return await this.post<IResponse<{ id: string }>>('/order/', data);
	}

	@autobind
	async editOrder(data: Partial<IOrder>) {
		return await this.post<IResponse<{ id: string }>>('/order/edit', data);
	}

	@autobind
	async deleteOrder(id: string) {
		return await this.post<IResponse<void>>('/order/delete', { id });
	}

	// ----

	@autobind
	async search(entityType: string, entityId: string | null, query: string, extras?: any) {
		return await this.post<IResponse<ISimpleQueryActionResult[]>>('/search/', { entityType, entityId, query, extras });
	}

	@autobind
	async multipleSearch(entityType: string, entityIds: string[], query: string) {
		return await this.post<IResponse<ISimpleQueryActionResult[]>>('/search/multiple', { entityType, entityIds, query });
	}

	@autobind
	async uploadFile(entityType: 'order' | 'place' | 'user' | 'service' | 'license', entityId: string, file: any) {
		const fd = new FormData;
		fd.append('entityType', entityType);
		fd.append('entityId', entityId);
		fd.append('file', file);
		return await this.post<IResponse<{ id: string}>>('/files/', fd);
	}

	fileLink(id: string | null) {
		if (!id) {
			return '';
		}

		if (id.startsWith('http')) {
			return id;
		}

		return this.host + '/files/' + id;
	}

}

export default API;