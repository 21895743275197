import React, { PureComponent, ReactNode } from 'react';

import { observable } from 'mobx';

import { Layout, Menu, Breadcrumb, Tooltip, Modal, Form, DatePicker, Switch } from 'antd';
import {
	UserOutlined, FormatPainterOutlined, AudioOutlined, PhoneOutlined,
	OrderedListOutlined, ContainerOutlined, HomeOutlined, CarOutlined, LogoutOutlined,
	CrownOutlined, BarcodeOutlined, NotificationOutlined, UnorderedListOutlined,
	FileTextOutlined, DatabaseOutlined,
} from '@ant-design/icons';

import { autobind } from 'core-decorators';
import gstore from '../../stores/gstore';
import { observer } from 'mobx-react';

import './style.scss';
import UsersPage from '../Users';
import KVSPage from '../KVS';

import logo from '../../logo-big.png';
import title from '../../title.png';
import PlaysPage from '../Plays';
import WorksPage from '../Works';
import ContactsPage from '../Contacts';
import { FormInstance } from 'antd/lib/form';
import PlayInput from '../../controls/PlayInput';


const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

@observer
class MainPage extends PureComponent {

	@observable reportVisible = false;

	@autobind
	async handleLogout() {
		await gstore.api.logout();
		await gstore.init();
	}

	@observable selectedPage: string = 'clients';

	pages: Record<string, ReactNode> = {
		admins: <UsersPage key="c1" role="admin" />,
		users: <UsersPage key="c2" role="user" />,
		plays: <PlaysPage />,
		kvs: <KVSPage />,
		works: <WorksPage />,
		codes: <ContactsPage />,
	}

	titles: Record<string, string> = {
		admins: 'Администраторы',
		users: 'Пользователи',
		plays: 'Спектакли',
		kvs: 'Информация в приложении',
		works: 'Уведомления',
		codes: 'Промокоды',
	}

	get SelectedPage() {
		return this.pages[this.selectedPage];
	}

	@observable allTime: boolean = true;
	formRef = React.createRef<FormInstance>();

	renderReport() {
		return (
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', justifyContent: 'flex-start' }}>
				<Form
					ref={this.formRef}
					layout="vertical"
					name="report"
					initialValues={{
						allTime: this.allTime
					}}
				>
					<Form.Item
						label="За всё время"
						name="allTime"
						rules={[]}
						tooltip="Выгрузить данные по всем покупкам"
					>
						<Switch defaultChecked={true} onChange={v => this.allTime = !this.allTime} />
					</Form.Item>
					<Form.Item
						label="Период"
						name="period"
						rules={[]}
						tooltip="Период, в который были совершены покупки"
					>
						<DatePicker.RangePicker disabled={this.allTime} />
					</Form.Item>
					<Form.Item
						label="Спектакль"
						name="playId"
						rules={[]}
						tooltip="Выгрузить данные только по конкретному спектаклю или по всем"
					>
						<PlayInput />
					</Form.Item>
				</Form>
			</div>
		)
	}

	render() {
		return (
			<Layout>
				<Modal
					title="Выгрузка отчёта о покупках"
					centered
					visible={this.reportVisible}
					onOk={async () => {
						const data = this.formRef.current!.getFieldsValue();
						if (data.period) {
							data.period = data.period.map((p: any) => p.format()).join('|');
						}
						console.log('data: ', data);
						const url = await gstore.api.getReportUrl(data);
						window.open(url);
						this.reportVisible = false;
					}}
					maskClosable={false}
					cancelText="Отмена"
					onCancel={() => this.reportVisible = false}
					okText="Скачать"
					width={600}
				>
					{this.renderReport()}
				</Modal>
				<Header className="header">
					<div className="logo">
						<img src={logo} style={{ maxHeight: 40 }} />
						{/* <img src={title} style={{ maxHeight: 40 }} /> */}
					</div>
					<Tooltip placement="bottom" title="Выйти">
						<div
							className="logout-button"
							style={{ float: 'right', cursor: 'pointer' }}
							onClick={this.handleLogout}
						>
							<LogoutOutlined style={{ color: 'white', fontSize: 18 }} />
						</div>
					</Tooltip>
				</Header>
				<Layout>
					<Sider width={250} className="site-layout-background">
						<Menu
							mode="inline"
							selectedKeys={[this.selectedPage]}
							onSelect={(info) => {
								if (info.key === 'report') {
									this.reportVisible = true;
									return;
								}
								this.selectedPage = String(info.key);
							}}
							style={{ height: '100%', borderRight: 0 }}
						>
							<Menu.Item key="users" icon={<OrderedListOutlined />}>Пользователи</Menu.Item>
							<Menu.Item key="admins" icon={<CrownOutlined />}>Администраторы</Menu.Item>
							<Menu.Item key="plays" icon={<AudioOutlined />}>Спектакли</Menu.Item>
							<Menu.Item key="works" icon={<NotificationOutlined />}>Уведомления</Menu.Item>
							<Menu.Item key="codes" icon={<BarcodeOutlined />}>Промокоды</Menu.Item>
							<Menu.Item key="kvs" icon={<FileTextOutlined />}>Информация</Menu.Item>
							<Menu.Item key="report" icon={<DatabaseOutlined />}>Отчёт о покупках</Menu.Item>
						</Menu>
					</Sider>
					<Layout style={{ padding: '0 24px 24px', maxHeight: 'calc(100vh - 60px)' }}>
						<Breadcrumb style={{ margin: '16px 0' }}>
							<Breadcrumb.Item>Панель администратора</Breadcrumb.Item>
							<Breadcrumb.Item>{this.titles[this.selectedPage]}</Breadcrumb.Item>
						</Breadcrumb>
						<Content
							className="site-layout-background"
							style={{
								padding: 24,
								paddingBottom: 5,
								margin: 0,
								minHeight: 280,
							}}
						>
							{this.SelectedPage}
						</Content>
					</Layout>
				</Layout>
			</Layout>
		);
	}
}

export default MainPage;