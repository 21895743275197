import React, { PureComponent, ReactNode } from "react";

import { observable } from "mobx";
import { observer } from "mobx-react";

import { autobind } from "core-decorators";
import cn from 'classnames';

import swal from 'sweetalert';
import { Button, Spin, Input, Tooltip } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import FTable, { IFTColumn, IFTRow } from "../../controls/FTable";
import gstore from "../../stores/gstore";

import './style.scss';

export interface ICustomRowAction {
	onClick: (id: string, row: IFTRow) => void,
	icon: React.ReactNode;
	buttonProps?: any;
	tooltip?: string;
};

export interface ICustomGlobalAction {
	onClick: () => void,
	icon: React.ReactNode;
	text?: string;
	buttonProps?: any;
};

export interface ITablePageProps<T> {
	defaultRowsOnPage?: number;

	listEndpoint: string;

	extras?: Record<string, string>;
	search?: boolean;

	onEdit?: (id: string, row: IFTRow) => void;
	onDelete?: (id: string, row: IFTRow) => Promise<void>;

	onCreate?: () => void;

	customRowActions?: (ICustomRowAction | ((id: string, row: IFTRow) => ICustomRowAction))[];
	customGlobalActions?: (ICustomGlobalAction | ReactNode)[];

	columns: IFTColumn[];
};

@observer
class TablePage<T extends {}> extends PureComponent<ITablePageProps<T>> {

	@observable page = 1;
	@observable rowsOnPage = this.props.defaultRowsOnPage || 25;
	@observable totalRows = 0;
	@observable search = '';
	@observable sorting: null | { name: string, direction: 'asc' | 'desc' } = null;

	@observable loading = true;

	@observable rows: IFTRow[] = [];

	componentDidMount() {
		this.load(this.props);
	}

	componentWillUpdate(nextProps: ITablePageProps<T>) {
		if (nextProps.extras !== this.props.extras) {
			this.load(nextProps);
		}
	}

	async load(props: ITablePageProps<T>) {
		this.loading = true;

		const result = await gstore.api.getList<Record<string, any>>(props.listEndpoint, this.page, this.rowsOnPage, Object.assign(
			{},
			(this.props.search ? (Object.assign({ search: this.search }, props.extras || {})) : props.extras),
			{
				sortingName: this.sorting ? this.sorting.name : null,
				sortingDirection: this.sorting ? this.sorting.direction : null
			}
		));
		if (result.result) {
			this.rows = result.data.list;
			this.totalRows = result.data.total;
		}

		this.loading = false;
	}

	get pagesCount() {
		return Math.floor(this.totalRows / this.rowsOnPage) + (this.totalRows % this.rowsOnPage !== 0 ? 1 : 0);
	}

	pageActive(p: number) {
		if (this.page === p) {
			return false;
		}

		return (p > 0 && p <= this.pagesCount)
	}

	@autobind
	handleGoToPage(e: React.MouseEvent, page: number) {
		if (!this.pageActive(page)) {
			return;
		}

		e.preventDefault();
		e.stopPropagation();

		this.page = page;
		this.load(this.props);
	}

	render() {

		const actions = [...(this.props.customRowActions || [])];

		if (this.props.onEdit) {
			actions.push({
				icon: <EditOutlined />,
				onClick: this.props.onEdit,
				tooltip: 'Редактировать'
			});
		}

		if (this.props.onDelete) {
			actions.push({
				icon: <DeleteOutlined />,
				onClick: async (id, row) => {
					if (!this.props.onDelete) {
						return;
					}

					const confirm = await swal({
						title: 'Вы уверены?',
						text: 'Вы уверены, что хотите безвозвратно удалить запись?',
						icon: 'warning',
						//@ts-ignore
						buttons: {
							cancel: "Отмена",
							yes: {
								text: 'Да, удалить',
								value: true,
								className: 'swal-button--danger'
							},
						},
					});

					if (confirm) {
						await this.props.onDelete(id, row);
						await this.load(this.props);
					}
				},
				buttonProps: {
					danger: true
				},
				tooltip: 'Удалить'
			});
		}

		return (
			<div className="page">
				{(this.props.onCreate || this.props.search || this.props.customGlobalActions?.length) ? (<div className="p-top">
					{this.props.onCreate ? (
						<Button icon={<PlusOutlined />} onClick={e => {
							if (this.props.onCreate) {
								this.props.onCreate();
							}
						}}>Создать</Button>
					) : null}
					{this.props.search ? (
						<Input.Search style={{ marginLeft: 10 }} onChange={e => { this.search = e.target.value; this.load(this.props); }} value={this.search} type="text" placeholder="Введите запрос для поиска..." />
					) : null}
					{this.props.customGlobalActions ? (
						this.props.customGlobalActions.map((ga, idx) => (
							//@ts-ignore
							(ga && ga.onClick && ga.icon) ? (
								//@ts-ignore
								<Button style={{ marginLeft: 10 }} key={idx} icon={ga.icon} onClick={e => { e.preventDefault(); ga.onClick(); }}>{ga.text}</Button>
							) : ga
						))
					) : null}
				</div>) : null}
				<div className="p-table">
					<FTable
						data={this.rows}
						loading={this.loading}
						onSorting={value => {
							this.sorting = value;
							this.load(this.props);
						}}
						sorting={this.sorting}
						columns={[
							...this.props.columns,
							{
								name: 'Действия',
								field: 'id',
								width: 140,
								notSortable: true,
								renderCell: (id: string, row) => {
									return (
										<div className="actions-list">
											{actions.map((ar, idx) => {
												const a = typeof ar === 'function' ? ar(id, row) : ar;
												if (a.tooltip) {
													return (
														<Tooltip title={a.tooltip}>
															<Button
																key={idx}
																icon={a.icon}
																onClick={e => { e.preventDefault(); a.onClick(id, row); }}
																{...a.buttonProps}
															/>
														</Tooltip>
													);
												} else {
													return (
														<Button
															key={idx}
															icon={a.icon}
															onClick={e => { e.preventDefault(); a.onClick(id, row); }}
															{...a.buttonProps}
														/>
													);
												}
											})}
										</div>
									)
								}
							},
						]}
					/>
				</div>
				<div className="p-pagination">
					<div className={cn('pagination', { disabled: this.loading })}>
						<div onClick={e => this.handleGoToPage(e, 1)} className={cn("pag-page first-page", { disabled: !this.pageActive(1) })}>
							<div className="page-number">&lt;&lt;</div>
							<div className="page-meta">to 1</div>
						</div>
						<div onClick={e => this.handleGoToPage(e, this.page - 1)} className={cn("pag-page previous-page", { disabled: !this.pageActive(this.page - 1) })}>
							<div className="page-number">&lt;</div>
							<div className="page-meta">to {this.page - 1}</div>
						</div>
						<div className="pag-page current-page">
							<div className="page-number">{this.loading ? (<Spin />) : this.page}</div>
							<div className="page-meta">{(this.page - 1) * this.rowsOnPage + 1}-{Math.min(this.page * this.rowsOnPage, this.totalRows)} of {this.totalRows}</div>
						</div>
						<div onClick={e => this.handleGoToPage(e, this.page + 1)} className={cn("pag-page next-page", { disabled: !this.pageActive(this.page + 1) })}>
							<div className="page-number">&gt;</div>
							<div className="page-meta">to {this.page + 1}</div>
						</div>
						<div onClick={e => this.handleGoToPage(e, this.pagesCount)} className={cn("pag-page last-page", { disabled: !this.pageActive(this.pagesCount) })}>
							<div className="page-number">&gt;&gt;</div>
							<div className="page-meta">to {this.pagesCount}</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default TablePage;